import React from 'react';
import WhyUsCard from './WhyTintashCard';

export default function index() {
  return (
    <div className="container p-0 ">
      <div className="row justify-content-center align-items-center m-0 p-0">
        <div className="col-12 m-0 p-0">
          <h1 className="latoBlack text-center main-heading ">Why work with Tintash</h1>
          <div className="title-description text-center ml-5">
            We don't just build products, we solve problems. We like to understand your vision,
            <br />
            identify the next problem to solve for your customers and then work on it.
          </div>
          <div className="why-tintash_grid pt-5">
            <WhyUsCard
              imgSrc={
                require('../../../assets/images/company/icons/whytintash/relationship.svg').default
              }
              title="Relationship prototyping"
              description="We prototype client relationships by first working on small, standalone projects to establish a foundation of trust and iron out any teething issues in order to hit the ground running for future projects."
            />
            <WhyUsCard
              imgSrc={require('../../../assets/images/company/icons/whytintash/teams.svg').default}
              title="Impact-driven teams"
              description="Our teams understand your product vision, and focus on creating products that deliver value. We strongly believe in being missionaries rather than feature-building mercenaries."
            />
            <WhyUsCard
              imgSrc={
                require('../../../assets/images/company/icons/whytintash/prototyping.svg').default
              }
              title="Product prototyping"
              description="Our prototyping process allows us to quickly test and validate ideas, reducing time to market and minimizing the risk of costly errors by focusing on high-impact and high-risk items first."
            />

            <WhyUsCard
              imgSrc={require('../../../assets/images/company/icons/whytintash/design.svg').default}
              title="Design thinking and lean-startup mindset"
              description="We approach every project with human-centered design thinking. We continuously test and iterate solutions to ensure that we're solving the right problem for both user and the business."
            />

            <WhyUsCard
              imgSrc={
                require('../../../assets/images/company/icons/whytintash/success.svg').default
              }
              title="Proven success"
              description="With over a decade of delivering successful projects in North America, we are trusted by Startups, Unicorns and Fortune 500s to create strong products."
            />

            <WhyUsCard
              imgSrc={
                require('../../../assets/images/company/icons/whytintash/balanced.svg').default
              }
              title="Balanced teams"
              description="Our teams are made up of individuals with diverse skills and perspectives, who can tackle problems from multiple angles and come up with smart solutions that solve real user problems."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
