import React from 'react';
import Layout from '../components/Shared/Layout';
import About from '../components/About';
import '../assets/css/about-us.css';
import * as ReactGA from 'react-ga';

class AboutUs extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'About.Us',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <About />
      </Layout>
    );
  }
}

export default AboutUs;
