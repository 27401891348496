import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './HeadingBanner.module.css';

function HeadingBanner({heading, description, imageSrc}) {
  return (
    <section style={{backgroundImage: `url(${imageSrc})`}} className={classes.banner}>
      <div className="container">
        <div className="row m-0 justify-content-center ">
          <div className={`col-12 ${classes.content} `}>
            <div className={`${classes.heading}`}>{heading}</div>
            <div className={`${classes.description}`}>{description}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

HeadingBanner.propTypes = {
  heading: PropTypes.element.isRequired,
  description: PropTypes.element.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default HeadingBanner;
