import React from 'react';

export default function WhyTintashCard({imgSrc, title, description}) {
  return (
    <div className="">
      <div className="">
        <div className="info">
          <img className="mw-100" width="48px" height="48px" src={imgSrc} />
          <h3 className="latoBlack heading text-left pt-3 m-0">{title}</h3>
        </div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
}
