import React from 'react';
import Img from 'gatsby-image';
import {StaticQuery, graphql} from 'gatsby';

// eslint-disable-next-line react/prefer-stateless-function
class Partners extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            aws: file(relativePath: {eq: "home-page/partners/aws.webp"}) {
              childImageSharp {
                fixed(width: 133) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            google: file(relativePath: {eq: "home-page/partners/google.webp"}) {
              childImageSharp {
                fixed(width: 78) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            unity: file(relativePath: {eq: "home-page/partners/unity.webp"}) {
              childImageSharp {
                fixed(width: 137) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            istqb: file(relativePath: {eq: "home-page/partners/istqb.webp"}) {
              childImageSharp {
                fixed(width: 102) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <div className="partners-main container-fluid">
            <div className="container">
              <div className="partners-heading text-center latoBold">Partner certifications</div>
              <div className="partner-container row">
                <figure className="partner-img-container col-12 col-sm-6 col-md-3 my-4">
                  <div className="partner-img mb-4">
                    <Img
                      className="mx-auto"
                      style={{display: 'inherit'}}
                      imgStyle={{objectFit: 'contain'}}
                      placeholderStyle={{objectFit: 'contain'}}
                      alt="aws"
                      fixed={data.aws.childImageSharp.fixed}
                      fadeIn={false}
                      loading="lazy"
                    />
                  </div>
                  <div className="partner-text-container">
                    <figcaption>AWS Certified Developers</figcaption>
                  </div>
                </figure>
                <figure className="partner-img-container col-12 col-sm-6 col-md-3 my-4">
                  <div className="partner-img mb-4">
                    <Img
                      className="mx-auto"
                      style={{display: 'inherit'}}
                      imgStyle={{objectFit: 'contain'}}
                      placeholderStyle={{objectFit: 'contain'}}
                      alt="google"
                      fixed={data.google.childImageSharp.fixed}
                      fadeIn={false}
                      loading="lazy"
                    />
                  </div>
                  <figcaption className="partner-text-container">
                    Google Certified Solution Architects
                  </figcaption>
                </figure>
                <figure className="partner-img-container col-12 col-sm-6 col-md-3 my-4">
                  <div className="partner-img mb-4">
                    <Img
                      className="mx-auto"
                      style={{display: 'inherit'}}
                      imgStyle={{objectFit: 'contain'}}
                      placeholderStyle={{objectFit: 'contain'}}
                      alt="unity"
                      fixed={data.unity.childImageSharp.fixed}
                      fadeIn={false}
                      loading="lazy"
                    />
                  </div>
                  <figcaption id="partner-unity-margin" className="partner-text-container">
                    Unity Certified Programmers
                  </figcaption>
                </figure>
                <figure className="partner-img-container col-12 col-sm-6 col-md-3 my-4">
                  <div className="partner-img mb-4">
                    <Img
                      className="mx-auto"
                      style={{display: 'inherit'}}
                      imgStyle={{objectFit: 'contain'}}
                      placeholderStyle={{objectFit: 'contain'}}
                      alt="istqb"
                      fixed={data.istqb.childImageSharp.fixed}
                      fadeIn={false}
                      loading="lazy"
                    />
                  </div>
                  <figcaption className="partner-text-container">
                    ISTQB Certified Testers
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default Partners;
