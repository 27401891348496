import React from 'react';
import {Link} from 'gatsby';
import {navigate} from 'gatsby'; // import navigate from gatsby
import {Helmet} from 'react-helmet';
import {Event} from '../GAClickEvent';
import OurLeadership from './OurLeadership';
import OurClient from './OurClient';
import Journey from '../Home/journey';
import WhyUs from './WhyUs';
import WhyTintash from './WhyTintash';
import Partners from '../Home/partners';
import OurStoryImage from '../../assets/images/about/ourstory-feature-img.webp';

import ClientTestimonialCard from './ClientTestimonial/ClientTestimonialCard';
import HeadingBanner from '../Shared/HeadingBanner/HeadingBanner';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>About Us | Tintash</title>
    </Helmet>
    <div>
      {/* Company Page Banner */}
      <div className="heading-banner-section">
        <HeadingBanner
          heading={
            <div>
              We are a{' '}
              <strong style={{color: '#1AA5F8'}}>
                community of top talent designers and engineers
              </strong>{' '}
              with shared-values who like to work together on meaningful projects.
            </div>
          }
          description={
            <div>
              Human centered design thinking is how we've built our culture, our playbooks, and our
              business.
            </div>
          }
          imageSrc={require('../../assets/images/company/topbanner-bg-2.webp').default}
        />
      </div>
      <section className="company-highlights">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <h1 className="heading-big">
                Stanford & Fortune 10
                <br /> trained leadership
              </h1>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="column details-container">
                <div className="highlight-container">
                  <h1 className="heading-medium">Company highlights</h1>
                  <div className="d-flex flex-wrap my-4">
                    <div className="numbers-container">
                      <h2 className="numbers">06</h2>
                      <p className="number-text">Countries</p>
                    </div>
                    <div className="numbers-container">
                      <h2 className="numbers">500+</h2>
                      <p className="number-text">Projects delivered</p>
                    </div>
                    <div className="numbers-container">
                      <h2 className="numbers">200+</h2>
                      <p className="number-text">Strong team</p>
                    </div>
                  </div>
                </div>
                <div className="our-client-container pt-4">
                  <h1 className="heading-medium">Our clients</h1>
                  <div className="d-flex flex-wrap mt-4">
                    <div className="numbers-container">
                      <h2 className="numbers">03</h2>
                      <p className="number-text">Unicorns</p>
                    </div>
                    <div className="numbers-container">
                      <h2 className="numbers">03</h2>
                      <p className="number-text">Fortune 500</p>
                    </div>
                    <div className="numbers-container">
                      <h2 className="numbers">150+</h2>
                      <p className="number-text">Startups</p>
                    </div>
                    <div className="numbers-container">
                      <h2 className="numbers">50+</h2>
                      <p className="number-text">Growth companies</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Company Page Banner  */}
      {/* Our Story */}
      <section className="company-page-story">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 our-story-text">
              <h1 className="latoBlack text-left heading">Our story</h1>
              <p className="latoRegular text-left description mt-3">
                It all started in 2007 with a breakfast at Hobee’s Palo Alto. Trained at Stanford
                d.school, both cofounders believed in prototyping solutions. And thus over the
                famous coffee cake, they decided to prototype their working relationship. It worked
                and became the foundation of Tintash's approach to every new client relationship.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <img className="our-story-image " src={OurStoryImage} alt="Our Story Image" />
            </div>
          </div>
        </div>
      </section>
      <section className="company-why-build-tintash">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <h1 className="heading-big">
                Why we <br />
                built Tintash
              </h1>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="why-build-description mt-3">
                <p className="paragraph-border">
                  Our goal was to build a company where top talent thrives in a vibrant culture,
                  driven by challenging and meaningful work.
                </p>
                <p className="paragraph">
                  We want our people to wake up excited to come to work, knowing they have a safe
                  space to share strong opinions, engage in passionate disagreements, and then go
                  enjoy a shared meal. This collaboration magic is at the core of building
                  successful products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Story */}
      {/* Why Us Section */}
      {/* Client Say about us */}
      <section className="company-client-section">
        <div className="container p-0">
          <div className="row justify-content-center align-items-center m-0">
            <div className="col-12 p-0">
              <h1 className="latoBlack text-center main-heading mb-5">
                What clients love about us
              </h1>
              <div className="row pt-3 m-0">
                <ClientTestimonialCard
                  title={<>Proactive communication</>}
                  description={
                    <>
                      Tintash understood our <br /> needs, and they were easy <br /> to communicate
                      with.
                    </>
                  }
                  name="Lionel Koh"
                  position="Director, Askvisor"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/stellar-communication.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Transparent processes</>}
                  description={
                    <>
                      They excel at best practices. We went in not knowing too much about iOS
                      development, so we trusted them to use the best practices to optimize the app.
                    </>
                  }
                  name="Forfit"
                  position="Co-founder"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/visibility-and-transparency.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Focus on delivery</>}
                  description={
                    <>
                      They were flexible and adaptable to the problem and issues encountered, and
                      very resourceful when dealing with issues; Extremely committed to delivering a
                      solid product.
                    </>
                  }
                  name="Patrick Cosgrove"
                  position="CTO, Lifeprint"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/delivery-process.svg').default
                  }
                />

                <ClientTestimonialCard
                  title={<>Rapid iterations towards excellence</>}
                  description={
                    <>
                      I think the most impressive thing is the ratio of professionalism to cost is
                      very high.
                    </>
                  }
                  name="David Cohen-Tanugi"
                  position="Co-Founder, Embr Labs"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/iterate-towards-excellence.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Complete project ownership</>}
                  description={
                    <>
                      Tintash Apps reliable workflow, helpful feedback, and long-term focus
                      contributed to a rewarding partnership.
                    </>
                  }
                  name="Basar Simitci"
                  position="CTO, Joyful Works"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/ownership-and-individual-responsibility.svg')
                      .default
                  }
                />

                <div className="col-lg-4 col-sm-12 col-md-6 mb-4 pb-1 d-flex ">
                  <div
                    className="card about-us get-started border-0 p-4 justify-content-center align-items-center w-100"
                    onClick={() => navigate('/contact')}
                    style={{cursor: 'pointer'}}
                  >
                    <p className="latoBlack text-center text-md-left heading px-0 px-md-4 mw-100">
                      Looking for such a team? We should talk.
                    </p>
                    <div>
                      <Link
                        className="btn btn btn-outline-success btn-started px-4 mt-2"
                        onClick={() =>
                          Event('Get Started', 'Get Started is clicked', window.location.pathname)
                        }
                        to="/contact"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company-different-section py-4">
        <WhyTintash />
      </section>

      <section className="company-culture">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 p-0">
              <h1 className="heading-big">
                Strong human
                <br />
                centred culture
              </h1>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <div className="why-build-description">
                <p
                  className="paragraph-border py-2 mt-5"
                  style={{color: '#4d5151', fontSize: '18px'}}
                >
                  <strong>Aspiring-teal</strong> company
                </p>
                <p
                  className="paragraph-border py-2 mt-5"
                  style={{color: '#4d5151', fontSize: '18px'}}
                >
                  <strong>83</strong> Net promoter score
                </p>
                <p
                  className="paragraph-border py-2 mt-5 "
                  style={{color: '#4d5151', fontSize: '18px'}}
                >
                  <strong>75%</strong> Employee retention rate
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Client Say about us */}
      {/* Number Section Code */}
      {/* <section className="about-us-journey">
        <Journey white />
      </section> */}
      {/* Number Section Code Complete */}
      {/* Leadership section  */}
      <section className="leadership-section py-5 my-3">
        <OurLeadership />
      </section>
      {/* Leadership section Complete */}
      {/* Our Values */}
      <section className="values-section py-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 my-2">
              <h1 className="latoBlack text-center main-heading mb-5">Our values</h1>
              <div className="values-container row mt-5">
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/faith.webp').default
                    }
                  />
                  <p className="text-center pt-3  values">Have faith</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/prototype.webp')
                        .default
                    }
                  />
                  <p className="latoRegular text-right pt-3  values">Prototype</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/character.webp')
                        .default
                    }
                  />
                  <p className="text-center pt-3  values">Akhlaq</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/enthusiasm.webp')
                        .default
                    }
                  />
                  <p className="text-center pt-3  values">Shokunin</p>
                </div>
                <div className="p-4  d-flex flex-column align-items-center values-border h-100">
                  <img
                    src={
                      require('../../assets/images/company/icons/our-values/new/proactive.webp')
                        .default
                    }
                  />
                  <p className=" text-center pt-3  values">Be proactive</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Values */}
      <Partners />
    </div>
  </>
);
